:root {
    --animate-delay: 0.5s;
}

* {
    scrollbar-width: none;
}

*::-webkit-scrollbar {
    display: none;
}

#map {
    position: absolute !important;
    inset: 0px auto 0px;
    width: 100%;
    max-width: 444px;
}

.MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.12) !important;
}

.animate__animated.animate__delay-500ms {
    -webkit-animation-delay: calc(var(--animate-delay) * 0.5);
    animation-delay: calc(var(--animate-delay) * 0.5);
}

.animate__animated.animate__delay-1500ms {
    -webkit-animation-delay: calc(var(--animate-delay) * 1.5);
    animation-delay: calc(var(--animate-delay) * 1.5);
}

.animate__animated.animate__delay-2500ms {
    -webkit-animation-delay: calc(var(--animate-delay) * 2.5);
    animation-delay: calc(var(--animate-delay) * 2.5);
}

.animate__animated.animate__delay-3500ms {
    -webkit-animation-delay: calc(var(--animate-delay) * 3.5);
    animation-delay: calc(var(--animate-delay) * 3.5);
}

.animate__animated.animate__delay-4500ms {
    -webkit-animation-delay: calc(var(--animate-delay) * 4.5);
    animation-delay: calc(var(--animate-delay) * 4.5);
}

.clouds {
    position: absolute;
    inset: 0;
}

.cloud {
    position: absolute;
    inset: auto;
    user-select: none;
}

.cloud-1 {
    top: 5%;
    left: 100%;
    width: 40%;
    animation: cloud 70s linear 10s infinite;
}

.cloud-2 {
    top: 18%;
    left: 100%;
    width: 35%;
    animation: cloud 50s linear 0s infinite;
}

.cloud-3 {
    top: 35%;
    left: 100%;
    width: 25%;
    animation: cloud 90s linear 20s infinite;
}

@-webkit-keyframes cloud {
    0% {
        margin-left: 0;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        margin-left: -100%;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

@keyframes cloud {
    0% {
        margin-left: 0;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        margin-left: -100%;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

@-webkit-keyframes zoomIn {
    from {
        -webkit-transform: scale3d(0, 0, 0);
        transform: scale3d(0, 0, 0);
    }
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes zoomIn {
    from {
        -webkit-transform: scale3d(0, 0, 0);
        transform: scale3d(0, 0, 0);
    }
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@-webkit-keyframes bounceIn {
    0%,
    20%,
    40%,
    60%,
    80%,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }
    0% {
        opacity: 1;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }
    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1)
    }
    40% {
        -webkit-transform: scale3d(.9, .9, .9);
        transform: scale3d(.9, .9, .9)
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03)
    }
    80% {
        -webkit-transform: scale3d(.97, .97, .97);
        transform: scale3d(.97, .97, .97)
    }
    to {
        opacity: 1;
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }
}

@keyframes bounceIn {
    0%,
    20%,
    40%,
    60%,
    80%,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }
    0% {
        opacity: 1;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }
    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1)
    }
    40% {
        -webkit-transform: scale3d(.9, .9, .9);
        transform: scale3d(.9, .9, .9)
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03)
    }
    80% {
        -webkit-transform: scale3d(.97, .97, .97);
        transform: scale3d(.97, .97, .97)
    }
    to {
        opacity: 1;
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }
}